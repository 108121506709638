import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './ProfileDropdown.module.css';
import ProfileAvatar from './ProfileAvatar';
import { signOut } from '../api/auth/signOut';
import { config } from '../config';
import { useAuth } from '../contexts/AuthContext';
import { RoleGuard } from './RoleGuard';

interface ProfileDropdownProps {
  user: {
    name: string;
    initials: string;
    email: string;
  };
  onClose: () => void;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ user, onClose }) => {
  const navigate = useNavigate();

  // Add scroll event listener to close dropdown
  useEffect(() => {
    const handleScroll = () => {
      onClose();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [onClose]);

  const handleSignOut = async () => {
    try {
      await signOut();
      // Redirect to the configured sign-out URL
      window.location.href = config.signOutRedirectUrl;
    } catch (error) {
      console.error('Failed to sign out:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.userInfo}>
        <ProfileAvatar initials={user.initials} />
        <div className={styles.nameEmailContainer}>
          <p className={styles.nameEmail}>{user.name}</p>
          <p className={styles.email}>{user.email}</p>
        </div>
      </div>
      
      <RoleGuard requiredRole={['admin', 'customer-service']}>
        <Link to="/internal/dashboard" className={styles.link} onClick={onClose}>
          Internal Dashboard
        </Link>
      </RoleGuard>
      
      <Link to="/settings" className={styles.link}>
        Settings
      </Link>
      
      <div className={styles.divider}></div>
      
      <div className={styles.supportSection}>
        <p className={styles.supportLabel}>Support</p>
        <Link to="/support" className={styles.link} onClick={onClose}>
          Open new case
        </Link>
        <Link to="/cases" className={styles.link} onClick={onClose}>
          View case list
        </Link>
      </div>
      
      <div className={styles.divider}></div>
      
      <button
        onClick={handleSignOut}
        className={styles.logoutButton}
      >
        Sign out
      </button>
    </div>
  );
};

export default ProfileDropdown;