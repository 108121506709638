import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './HeaderSub.module.css';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  BUILD_URL,
  BLESS_URL,
  FASHION_FUN_URL,
} from '../constants';

const HeaderSub = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  const links = [
    { href: BUILD_URL, text: 'Build' },
    { href: BLESS_URL, text: 'Bless' },
    { href: FASHION_FUN_URL, text: 'Fashion Fun' },
  ];

  return (
    <div className={styles.subheader}>
      {/* Mobile Menu Button */}
      <button 
        className={styles.mobileMenuButton}
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        aria-label="Toggle navigation menu"
      >
        <Bars3Icon className="h-6 w-6" />
      </button>

      {/* Desktop Navigation */}
      <nav className={styles.subheaderNav}>
        {links.map((link, index) => (
          <React.Fragment key={link.href}>
            <a 
              href={link.href} 
              target="_blank" 
              rel="noopener noreferrer" 
              className={`${styles.subheaderLink} ${styles.noSelect}`}
            >
              {link.text}
            </a>
            {index < links.length - 1 && <div className={styles.divider} />}
          </React.Fragment>
        ))}
      </nav>

      {/* Mobile Navigation Overlay */}
      <div className={`${styles.mobileNav} ${isMobileMenuOpen ? styles.mobileNavOpen : ''}`}>
        <div className={styles.mobileNavHeader}>
          <button 
            onClick={() => setIsMobileMenuOpen(false)}
            className={styles.closeButton}
            aria-label="Close navigation menu"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <nav className={styles.mobileNavLinks}>
          {links.map((link) => (
            <a
              key={link.href}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.mobileNavLink}
              onClick={handleLinkClick}
            >
              {link.text}
            </a>
          ))}
        </nav>
      </div>

      {/* Overlay Background */}
      {isMobileMenuOpen && (
        <div 
          className={styles.overlay}
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </div>
  );
};

export default HeaderSub;